import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';

import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { PanelItem } from '@components/panels/PanelItems';
import { IPlan, PlanService } from '@services/SubscriptionPlans';
import { ContactOwnersService } from '@services/ContactOwners';
import { addNextYearsWeeks, countrySpecificCurrency, countrySpecificDistanceUnit, dateToISOFormat, getTimeDifference } from '@utils/misc/functions';
import Label from '@components/inputs/text/Label';
import DatePicker from '@components/inputs/DateInput';
import DropDownMenu, { IChoice, findOptionFromList } from '@components/inputs/DropDownMenu';
import useServiceHook from '@hooks/useServiceHook';
import Elliptical from '@components/spinners/Elliptical/Elliptical';
import { textBoxStyle } from '@utils/styles/textboxStyle';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';
import { emailMinusSplendDomain, keepDigits } from '@utils/misc/functions';


export default function UKFOAgreementSchedule({ breadcrumbObject }: BreadcrumbProps) {
    const todaysDate = dateToISOFormat(new Date());  // YYYY-mm-dd
    const dropDownPleaseSelect = { label: '- please select -', value: '', enabled: false };

    const { formState: { driverDetails, contractDetails }, updateFormState } = useUserJourneyContext();
    const [executionDate, setExecutionDate] = useState<string>(contractDetails.executionDate || todaysDate);
    const [subsStartDate, setSubsStartDate] = useState<string>(contractDetails.subsStartDate || todaysDate);

    // The findPlanLen fn is a custom wrapper over a generic one, to search only on planLengths,
    // intended to be reused.
    // The state is tried first to be defaulted with the planLengths list option matching the
    // value from context, but if not possible, one from the options set will be used.
    const planLengths: IChoice[] = [
        { label: '4', value: '4', enabled: true },
        { label: '5', value: '5', enabled: true }
    ];
    const defaultPlanLen = planLengths[1];
    const findPlanLen = (optionValue: string | number) => findOptionFromList(optionValue, planLengths);
    const [planLen, setPlanLen] = useState<IChoice>(findPlanLen(contractDetails.planLen) || defaultPlanLen);

    // The mapping below has to be kept in sync with the planLengths object from above. The
    // default planLen will give the default that's picked from the mapping and will prefill
    // the setup fee form input field, if no other value is available from context.
    const planLen2defaultSetupFee: { [key: string]: string } = {
        '4': '299',
        '5': '499'
    };
    const [setupFee, setSetupFee] = useState<string>(contractDetails.planSetupFee || planLen2defaultSetupFee[defaultPlanLen.value]);

    const distanceAllowanceList = [
        { label: '700', value: '700', enabled: true },
        { label: '1000', value: '1000', enabled: true },
    ];
    const findDistAllowance = (optionValue: string) => findOptionFromList(optionValue, distanceAllowanceList);
    const [distanceAllowance, setDistanceAllowance] = useState<IChoice>(findDistAllowance(String(contractDetails.planWeeklyDistAllowance)) || distanceAllowanceList[1]);

    const excessFeeList = [
        dropDownPleaseSelect,
        { label: '0.10', value: '0.10', enabled: true },
        { label: '0.20', value: '0.20', enabled: true },
        { label: 'N/A', value: 'N/A', enabled: true }
    ];
    const findExcessFee = (optionValue: string) => findOptionFromList(optionValue, excessFeeList);
    const [excessFee, setExcessFee] = useState<IChoice>(findExcessFee(contractDetails.planAdditionalDistCharge.toFixed(2)) || excessFeeList[2]);

    const checkAllowSearch = (planLenOption: string | number, distAllowanceOption: string, excessFeeOption: string): boolean =>
        (planLenOption != '' && Number(planLenOption) !== 0) && (distAllowanceOption !== '') && (excessFeeOption !== '');

    const [allowSearch, setAllowSearch] = useState<boolean>(checkAllowSearch(planLen.value, distanceAllowance.value, excessFee.value));

    // Set default value for the insurance from context, if there is one.
    const insuranceOptions = [
        { label: 'To be organised by Splend', value: 'Splend', enabled: true },
        { label: 'To be organised by Hirer', value: 'Hirer', enabled: true }
    ];
    const defaultInsurance = insuranceOptions.find(item => item.value === contractDetails.insuranceCover) || insuranceOptions[0];

    // If a plan is found in the context, set it as default, being the user's last selection. Otherwise,
    // default as undefined instead of any value to eliminate the risk of duplicating the available options.
    let defaultPlan = contractDetails.planId
        ? { label: contractDetails.planName, value: contractDetails.planId, enabled: true }
        : undefined;

    // When plan length changes, fire backend request to get corresponding plans. It shall
    // happen only if plan length is not 0, being the reason for the condition set below,
    // representing the hook's allow-to-run condition. So that acts like a on-off switch,
    // and the dependencies list (coincidentally here the same var) acts like a trigger.
    const [loading, , , availablePlans] = useServiceHook(
        PlanService.getPlans,
        [{ plan_type: driverDetails.plan, state: "LDN", term: planLen.value, weekly_distance_allowance: distanceAllowance.value, excess_fee: excessFee.value }, driverDetails.country],  // carInfo.hub plays the role of state
        allowSearch,
        [planLen.value, distanceAllowance.value, excessFee]
    );

    //Retrieves all available zoho users for each country. 
    const [userLoading, , , availableContactOwners] = useServiceHook(
        ContactOwnersService.getContactOwners,
        [driverDetails.country],
        true,
    );

    let dropdown = [];
    let dropdownDefault: Partial<IChoice | undefined> = undefined;
    let dropdownDefaultID: string = "";

    // set default contact owner from availableContactOwner list
    if (availableContactOwners) {
        const defaultContactOwnerIndex = availableContactOwners.findIndex((index: any) => emailMinusSplendDomain(index.email) === emailMinusSplendDomain(contractDetails.contactOwnerEmail));
        const selectedContact = availableContactOwners[defaultContactOwnerIndex];
        dropdownDefaultID = selectedContact.id;
        dropdownDefault = { label: selectedContact.label, value: selectedContact.id, enabled: true };
        dropdown = availableContactOwners.map((item: any) => ({
            value: item.id,
            label: item.label,
            enabled: true
        }));
    }

    // TODO: find a way to update cusotmer email on contact owner change. Currently we have no use for it but we should aim to keep values consistent
    // with selected contact owner
    const onContactOwnerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateFormState(UserFormStates.contractDetails, { contactOwner: e.currentTarget.options[e.currentTarget.selectedIndex].label, contactOwnerId: e.currentTarget.value });
    };

    useEffect(() => { // runs only at first page render
        // if no executionDate, take it from state, which should've been set to today
        contractDetails.executionDate === '' &&
            updateFormState(UserFormStates.contractDetails, { executionDate });

        // if no subsStartDate, take it from state, which should've been set to today
        contractDetails.subsStartDate === '' &&
            updateFormState(UserFormStates.contractDetails, { subsStartDate });

        // On the first time the user gets on the view, the plan len (state) drop down will
        // be initialised with a value from predefined (as nothing would be available inside
        // context at that time). That default value must also be put into the context as the
        // other configuration to allow a plan selection would already be in place. A plan
        // selection does not set the plan len in the context.
        updateFormState(UserFormStates.contractDetails, { planLen: Number(planLen.value) });
    }, []);

    useEffect(  // update context's subsStartDate field upon state update
        () => updateFormState(UserFormStates.contractDetails, { subsStartDate }),
        [subsStartDate]
    );

    useEffect(  // update context's executionDate field upon state update
        () => updateFormState(UserFormStates.contractDetails, { executionDate }),
        [executionDate]
    );

    useEffect(  // update context's planSetupFee when user types new value for setup fee
        () => updateFormState(UserFormStates.contractDetails, { planSetupFee: setupFee }),
        [setupFee]
    );

    // Runs on page re-render. In some cases the defaultInsurance value is the same as the one from the context,
    // in which case basically nothing happens. However, this call is necessary for when the user lands on this
    // page for the first time and there's no default in context, hence the need to put one in there - that must
    // be linked with the default selection from the drop down on the form (below).
    useEffect(() => updateFormState(UserFormStates.contractDetails, { insuranceCover: defaultInsurance.value }), []);

    useEffect(() => {  // when planLen or subsStartDate changes, update the subsEndDate accordingly
        if (Number(planLen.value) === 0 || subsStartDate === '') return
        const startDate = new Date(subsStartDate);
        const endDate = addNextYearsWeeks(startDate, Number(planLen.value));
        updateFormState(UserFormStates.contractDetails, { subsEndDate: dateToISOFormat(endDate) });
    }, [planLen, subsStartDate]);

    const onPlanLenChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // When plan length changes, update the context with the new value
        // and reset the selected plan from the context, since a new list
        // will be returned by the, what would be at that time, an ongoing
        // backend call, and the user will have to choose from new values

        // The setAllowSearch is being computed inside the setPlanLen function because React
        // updates the state asynchronously. Due to this, a call of setAllowSearch outside
        // this function would use the outdated plan length option. Putting the setAllowSearch
        // inside here, it is possible to use the newly selected plan len option.
        setPlanLen(() => {
            const newPlanLen: IChoice = findPlanLen(event.target.value);
            setAllowSearch(checkAllowSearch(newPlanLen.value, distanceAllowance.value, excessFee.value));
            return newPlanLen;
        });
        updateFormState(UserFormStates.contractDetails, { planLen: Number(event.target.value) });
        updateFormState(UserFormStates.contractDetails, {
            planId: '',
            planName: '',
            planWeeklyFee: 0,
            // planSetupFee can be reset via state update below
            planMinimumPeriod: 0,
            planWeeklyDistAllowance: 0,
            planAdditionalDistCharge: 0,
        });

        // Used to set the default contact owner id. Plan length must always be selected to continue moving through
        // the form. There doesn't seem to be a way to set the default dropdownID without triggering an infinite loop
        // of rerenders. Therefore, setting the default has been moved here as this will always and only be selected.
        if (contractDetails.contactOwnerId === "") {
            updateFormState(UserFormStates.contractDetails, { contactOwnerId: dropdownDefaultID });
        }
        setSetupFee(planLen2defaultSetupFee[event.target.value]);
    };

    const onPlanChange = (e: ChangeEvent<HTMLSelectElement>) => {
        // When plan changes, update the context. Stored value will be used
        // if the user moves back and forth through the step, so at later
        // moment, when back on this page, that value is used as default 
        const selectedPlan = availablePlans.filter((item: IPlan) => item.id === e.target.value)[0];
        updateFormState(
            UserFormStates.contractDetails,
            {
                planId: selectedPlan.id,
                planName: selectedPlan.name,
                planWeeklyFee: selectedPlan.weeklyHireFee,
                // planSetupFee can be reset via state update below
                planMinimumPeriod: selectedPlan.minimumPeriodWeeks,
                planWeeklyDistAllowance: selectedPlan.weeklyDistAllowance,
                planAdditionalDistCharge: selectedPlan.additionalDistCostPerUnit,
                // add fleetIncentive?
            }
        );
    };

    const onKMAllowanceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        // At the moment, it is technically possible to select an option that represent an empty string
        // (see the dropDownPleaseSelect var), in which case no backend request should be performed to
        // get plans. For this, checkAllowSearch is invoked to validate the params which are directly
        // used for the call.
        // This is done in the setDistanceAllowance function, because the state update is async, so 
        // there's the risk of using outdated state values (reason for using the event value).
        setDistanceAllowance(() => {
            const newDistAllowance: IChoice = findDistAllowance(event.target.value);
            setAllowSearch(checkAllowSearch(planLen.value, newDistAllowance.value, excessFee.value));
            return newDistAllowance;
        });
        updateFormState(UserFormStates.contractDetails, {
            planId: '',
            planName: '',
            planWeeklyFee: 0,
            planMinimumPeriod: 0,
            planWeeklyDistAllowance: 0,
            planAdditionalDistCharge: 0,
        });
    };

    const onExcessDistanceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        // At the moment, it is technically possible to select an option that represent an empty string
        // (see the dropDownPleaseSelect var), in which case no backend request should be performed to
        // get plans. For this, checkAllowSearch is invoked to validate the params which are directly
        // used for the call.
        // This is done in the setExcessFee function, because the state update is async, so 
        // there's the risk of using outdated state values (reason for using the event value).
        setExcessFee(() => {
            const newExcessFee: IChoice = findExcessFee(event.target.value);
            setAllowSearch(checkAllowSearch(planLen.value, distanceAllowance.value, newExcessFee.value));
            return newExcessFee;
        });
        updateFormState(UserFormStates.contractDetails, {
            planId: '',
            planName: '',
            planWeeklyFee: 0,
            planMinimumPeriod: 0,
            planWeeklyDistAllowance: 0,
            planAdditionalDistCharge: 0,
        });
    };

    useEffect(() => {
        breadcrumbObject['Agreement schedule']['Execution Date'] = contractDetails.executionDate;
        breadcrumbObject['Agreement schedule']['Subscription Start Date'] = contractDetails.subsStartDate;
        breadcrumbObject['Agreement schedule']['Plan Length'] = contractDetails.planLen.toString();
        breadcrumbObject['Agreement schedule']['Chargebee Plan'] = contractDetails.planName;
        breadcrumbObject['Agreement schedule']['Total Time Spent on Journey'] = getTimeDifference(breadcrumbObject['Vehicle assignment']['Customer Started Journey'], new Date());
    }, [contractDetails]);

    const divStyles: CSSProperties = {
        margin: '2.5vh 0vw',
    };

    return (<>
        <div style={divStyles}>
            <p style={{ margin: '2.5vh 0' }}>Please ensure the key terms for the customer are correct.</p>
            <Label text='Contact Owner/Authorised Signatory' requiredFieldInd={true} />
            {userLoading === false || userLoading === null
                ? <DropDownMenu
                    menuName={'contactOwnerDropMenu'}
                    defaultVal={dropdownDefault}
                    required={true}
                    choices={dropdown}
                    onSelect={(event: ChangeEvent<HTMLSelectElement>) => onContactOwnerChange(event)}
                />
                : <Elliptical />
            }
        </div>
        <div style={divStyles}>
            <Label text='Contract execution date' requiredFieldInd={true} />
            <DatePicker
                name='contractExeDate'
                defaultVal={executionDate}
                min={todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setExecutionDate(e.currentTarget.value)}
            />
        </div>
        <div style={divStyles}>
            <Label text='Subscription start date' requiredFieldInd={true} />
            <DatePicker
                name='contractStartDate'
                defaultVal={subsStartDate}
                min={todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setSubsStartDate(e.currentTarget.value)}
            />
        </div>
        <div style={divStyles}>
            <Label text='Plan length (years)' requiredFieldInd={true} />
            <DropDownMenu
                menuName='planLenDropMenu'
                defaultVal={planLen}
                required={true}
                choices={planLengths}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) => onPlanLenChange(e)}
            />
        </div>
        <div style={divStyles}>
            <Label text='Mileage Allowance' requiredFieldInd={true} />
            <DropDownMenu
                menuName='distanceAllowanceDropMenu'
                defaultVal={distanceAllowance}
                required={true}
                choices={distanceAllowanceList}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) => onKMAllowanceChange(e)}
            />
        </div>
        <div style={divStyles}>
            <Label text={`Mileage excess fee (${countrySpecificCurrency(driverDetails.country)})`} requiredFieldInd={true} />
            <DropDownMenu
                menuName='excessFeeDropMenu'
                defaultVal={excessFee}
                required={true}
                choices={excessFeeList}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) => onExcessDistanceChange(e)}
            />
        </div>
        <div style={divStyles} >
            <Label text='Plan' requiredFieldInd={true} />
            {loading === false || loading === null
                ? <DropDownMenu
                    menuName='planDropMenu'
                    defaultVal={defaultPlan}
                    required={true}
                    choices={availablePlans
                        ? availablePlans.map((item: any) => ({ label: item.name, value: item.id, enabled: true }))
                        : []
                    }
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => onPlanChange(e)}
                />
                : <div style={{ height: '5vh' }}><Elliptical /></div>
            }
        </div>
        <div style={{ margin: '2.5vh 0vw' }}>
            <PanelItem
                header='Minimum period (weeks)' headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planMinimumPeriod || ''} />
            <PanelItem
                header={`Weekly hire fee (${countrySpecificCurrency(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planWeeklyFee || ''} />
            <PanelItem
                header={`Permitted mileage per week (${countrySpecificDistanceUnit(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planWeeklyDistAllowance || ''} />
            <PanelItem
                header={`Additional mileage charge (${countrySpecificCurrency(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planAdditionalDistCharge || ''} />
        </div>
        <div style={divStyles}>
            <Label text={`Setup fee (${countrySpecificCurrency(driverDetails.country)})`} requiredFieldInd={true} />
            <input
                type='text'
                style={textBoxStyle}
                name='fee'
                value={setupFee}
                required={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSetupFee(keepDigits(e.target.value))}
            />
        </div>
        <div style={divStyles}>
            <Label text='Comprehensive car insurance' />
            <DropDownMenu
                menuName='planLenDropMenu'
                defaultVal={defaultInsurance}
                required={true}
                choices={insuranceOptions}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) =>
                    updateFormState(UserFormStates.contractDetails, { insuranceCover: e.currentTarget.value })}
            />
        </div>
    </>);
};
