import React, { useState, useEffect, ChangeEvent } from 'react';

import { Tooltip } from '@components/modal/Tooltip';
import NumericInput from '@components/inputs/NumericInput';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';


export function DynamicPricingAU() {
    const { formState, updateFormState } = useUserJourneyContext();
    const { splendInitiatives, contractDetails } = formState;
    const [loading, setLoading] = useState(true);
    const [tripDiscount, setTripDiscount] = useState(0);
    const [kmAllowanceDiscount, setKmAllowanceDiscount] = useState(0);
    const [minTermDiscount, setMinTermDiscount] = useState(0);
    const [vehicleUtilDiscount, setVehicleUtilDiscount] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [adjustedWeeklyHireFee, setAdjustedWeeklyHireFee] = useState(0);

    const rowStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    };

    const itemStyle: React.CSSProperties = {
        flex: 1,
        padding: '10px',
        boxSizing: 'border-box',
    };

    const textStyle: React.CSSProperties = {
        ...itemStyle,
        fontWeight: 'bold',
    };

    const numberStyle: React.CSSProperties = {
        ...itemStyle,
        textAlign: 'right',
    };

    const lineStyle: React.CSSProperties = {
        color: '#E1E1E1',
        borderColor: '#E1E1E1',

    }

    enum MinimumPeriodWeeks {
        fourWeeks = 4,
        sixWeeks = 6,
        eightWeeks = 8,
        tenWeeks = 10,
        twelveWeeks = 12,
        fourteenWeeks = 14,
    }

    const calculateTripDiscount = (tripCount: string) => {
        const lowTripCountDiscount = 0 // no discount applied
        const moderateTripCountDiscount = 20 // Discount of $20 applied
        const highTripCountDiscount = 50 // Discount of $50 applied

        const lowTripCount = "0 - 2999"
        const moderateTripCount = "3000 - 6999"
        const highTripCount = "7000+"

        if (tripCount === lowTripCount) return lowTripCountDiscount;
        if (tripCount === moderateTripCount) return moderateTripCountDiscount;
        if (tripCount === highTripCount) return highTripCountDiscount;
        return 0;
    };

    const calculateKmAllowanceDiscount = (kmAllowance: string) => {
        const allowance = Number(kmAllowance);
        return allowance === 1200 ? 0 : 20;
    };

    const calculateMinimumTermDiscount = (minimumTerm: string) => {
        const minTerm = Number(minimumTerm);
        const noDiscount = 0 // No discount applied
        const moderateDiscount = 10 //Discount of $10 applied
        const highDiscount = 20 // Discount of $20 applied

        if (minTerm === MinimumPeriodWeeks.fourWeeks) return noDiscount;
        if (minTerm === MinimumPeriodWeeks.sixWeeks) return noDiscount;
        if (minTerm === MinimumPeriodWeeks.eightWeeks) return noDiscount;
        if (minTerm === MinimumPeriodWeeks.tenWeeks) return moderateDiscount;
        if (minTerm === MinimumPeriodWeeks.fourteenWeeks) return highDiscount;
        return 0
    };

    const calculateVehicleUtilisationDiscount = (vehicleUtilisation: string) => {
        const noDiscount = 0 // No discount applied
        const discount = 20 // Discount of $20 applied
        return vehicleUtilisation == "Yes" ? discount : noDiscount;
    };

    const calculateIncentiveDiscount = (trip: number, kmAllowance: number, minimumTerm: number, vehicleUtil: number) => {
        return trip + kmAllowance + minimumTerm + vehicleUtil;
    };


    useEffect(() => {
        const tripDiscount = calculateTripDiscount(splendInitiatives.tripCount);
        const kmAllowanceDiscount = calculateKmAllowanceDiscount(splendInitiatives.weeklyDistanceAllowance);
        const minTermDiscount = calculateMinimumTermDiscount(splendInitiatives.minimumPeriodWeeks);
        const vehicleUtilDiscount = calculateVehicleUtilisationDiscount(splendInitiatives.vehicleUtilisation);

        const totalDiscount = calculateIncentiveDiscount(tripDiscount, kmAllowanceDiscount, minTermDiscount, vehicleUtilDiscount);
        const adjustedWeeklyHireFeeRounded = Number((contractDetails.planWeeklyFee - totalDiscount).toFixed(2));
        updateFormState(UserFormStates.splendInitiatives, { tripDiscount });
        updateFormState(UserFormStates.splendInitiatives, { weeklyDistanceAllowanceDiscount: kmAllowanceDiscount });
        updateFormState(UserFormStates.splendInitiatives, { minimumPeriodWeeksDiscount: minTermDiscount });
        updateFormState(UserFormStates.splendInitiatives, { availableVehicleDiscount: vehicleUtilDiscount });
        updateFormState(UserFormStates.splendInitiatives, { totalDynamicPricing: totalDiscount });
        updateFormState(UserFormStates.splendInitiatives, { adjustedWeeklyHireFee: adjustedWeeklyHireFeeRounded })

        setTripDiscount(tripDiscount);
        setKmAllowanceDiscount(kmAllowanceDiscount);
        setMinTermDiscount(minTermDiscount);
        setVehicleUtilDiscount(vehicleUtilDiscount);
        setTotalDiscount(totalDiscount);

        setLoading(false);
    }, [splendInitiatives.tripCount, splendInitiatives.weeklyDistanceAllowance, splendInitiatives.minimumPeriodWeeks, splendInitiatives.vehicleUtilisation, updateFormState]);

    const tripCountOptions: IChoice[] = [
        // first item in list is also the default plan
        { value: '0 - 2999', label: '0 - 2999', enabled: true },
        { value: '3000 - 6999', label: '3000 - 6999', enabled: true },
        { value: '7000+', label: '7000+', enabled: true },
    ];

    const kmAllowanceOptions: IChoice[] = [
        // first item in list is also the default plan
        { value: '1000', label: '1000', enabled: true },
        { value: '1200', label: '1200', enabled: true },
    ];

    const minimumPeriodWeeksOptions: IChoice[] = [
        // first item in list is also the default plan
        { value: '6', label: '6', enabled: true },
        { value: '10', label: '10', enabled: true },
        { value: '14', label: '14', enabled: true },
    ];

    const vehicleUtilisationOptions: IChoice[] = [
        // first item in list is also the default plan
        { value: 'Yes', label: 'Yes', enabled: true },
        { value: 'No', label: 'No', enabled: true },

    ];

    const selectedVehicleUtilisationOption = minimumPeriodWeeksOptions.find(option => option.value === splendInitiatives.vehicleUtilisation) || vehicleUtilisationOptions[0]

    const selectedMinimumPeriodWeeksOption = minimumPeriodWeeksOptions.find(option => option.value === splendInitiatives.minimumPeriodWeeks) || minimumPeriodWeeksOptions[0]

    const selectedkmAllowanceOption = kmAllowanceOptions.find(option => option.value === splendInitiatives.weeklyDistanceAllowance) || kmAllowanceOptions[0]

    const selectedTripCountOption = tripCountOptions.find(option => option.value === splendInitiatives.tripCount) || tripCountOptions[0]

    return (
        <div style={{ margin: '2.5vh 0' }}>
            <div>
                <div style={textStyle}>Trip count</div>
                {/* <div style={numberStyle}>{splendInitiatives.tripCount}</div> */}
                <DropDownMenu
                    menuName='tripCountDropdownMenu'
                    defaultVal={selectedTripCountOption}
                    required={true}
                    choices={tripCountOptions}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) =>
                        updateFormState(UserFormStates.splendInitiatives, { tripCount: e.currentTarget.value })}
                />
            </div>

            <div style={rowStyle}>
                <div style={textStyle}>Discount ($)</div>
                <div style={numberStyle}>{tripDiscount}</div>
            </div>

            <div>
                <hr style={lineStyle} />
            </div>

            <div>
                <div style={textStyle}>KM allowance</div>
                {/* <div style={numberStyle}>{splendInitiatives.weeklyKilometreAllowance}</div> */}
                <DropDownMenu
                    menuName='kmAllowanceDropdownMenu'
                    defaultVal={selectedkmAllowanceOption}
                    required={true}
                    choices={kmAllowanceOptions}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) =>
                        updateFormState(UserFormStates.splendInitiatives, { weeklyDistanceAllowance: e.currentTarget.value })} //right field?
                />
            </div>

            <div style={rowStyle}>
                <div style={textStyle}>Discount ($)</div>
                <div style={numberStyle}>{kmAllowanceDiscount}</div>
            </div>

            <div>
                <hr style={lineStyle} />
            </div>

            <div>
                <div style={textStyle}>Minimum term (weeks)</div>
                <DropDownMenu
                    menuName='minimumTermDropdownMenu'
                    defaultVal={selectedMinimumPeriodWeeksOption}
                    required={true}
                    choices={minimumPeriodWeeksOptions}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) =>
                        updateFormState(UserFormStates.splendInitiatives, { minimumPeriodWeeks: e.currentTarget.value })} //right field?
                />
            </div>

            <div style={rowStyle}>
                <div style={textStyle}>Discount ($)</div>
                <div style={numberStyle}>{minTermDiscount}</div>
            </div>

            <div>
                <hr style={lineStyle} />
            </div>

            <div>
                <div style={textStyle}>Vehicle utilisation (Greater than 6)</div>
                <DropDownMenu
                    menuName='vehicleUtilisationDropdownMenu'
                    defaultVal={selectedVehicleUtilisationOption}
                    required={true}
                    choices={vehicleUtilisationOptions}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) =>
                        updateFormState(UserFormStates.splendInitiatives, { vehicleUtilisation: e.currentTarget.value })} //right field?
                />
            </div>

            <div style={rowStyle}>
                <div style={textStyle}>Discount ($)</div>
                <div style={numberStyle}>{vehicleUtilDiscount}</div>
            </div>

            <div>
                <hr style={lineStyle} />
            </div>

            <div style={rowStyle}>
                <div style={textStyle}>Total weekly discount ($)</div>
                <div style={numberStyle}>{totalDiscount}</div>
            </div>

            <div style={rowStyle}>
                <div style={textStyle}>Adjusted weekly hire fee ($)</div>
                <div style={numberStyle}>{splendInitiatives.adjustedWeeklyHireFee.toFixed(2)}</div>
            </div>
        </div>
    );
};


export const DynamicPricingUK = () => {

    const { formState: { splendInitiatives, contractDetails }, updateFormState } = useUserJourneyContext();
    const [additionalDiscount, setAdditionalDiscount] = useState<number | undefined>(splendInitiatives.additionalDiscount || undefined);

    // Generic search function that is expected to always find at most one item within the
    // given array of options. For the intended usages from this component, Undefined is
    // also fine. One example is when this is used to get an option using a context value
    // to find it, the value is not yet populated, so the returned value is Undefined.
    const findOptionFromList = (optionValue: string | number, choices: IChoice[]): IChoice => choices.filter(option => option.value === String(optionValue))[0];

    // The value represents the discount to be applied depending on the choice and the
    // label is what's being displayed to the user.
    const tripCountOptions: IChoice[] = [
        { value: '0', label: '0 - 2999', enabled: true },
        { value: '10', label: '301 - 1000', enabled: true },
        { value: '20', label: '1001 - 2000', enabled: true },
        { value: '30', label: '2001 - 3000', enabled: true },
        { value: '40', label: '3001+', enabled: true },
    ];
    // Custom tailored function to search by value only inside the tripCountOptions list
    const findOptionFromTrips = (optionValue: string | number) => findOptionFromList(optionValue, tripCountOptions);
    const [tripCount, setTripCount] = useState<IChoice>(findOptionFromTrips(splendInitiatives.tripDiscount) || tripCountOptions[0]);

    // The value represents the discount to be applied depending on the choice and the
    // label is what's being displayed to the user.
    const distanceAllowanceOptions: IChoice[] = [
        { value: '30', label: '700', enabled: true },
        { value: '0', label: '1000', enabled: true },
    ];
    // Custom tailored function to search by value only inside the distanceAllowanceOptions list
    const findOptionFromDistanceAllowances = (optionValue: string | number) => findOptionFromList(optionValue, distanceAllowanceOptions);
    const [distanceAllowance, setDistanceAllowance] = useState<IChoice>(findOptionFromDistanceAllowances(splendInitiatives.weeklyDistanceAllowanceDiscount) || distanceAllowanceOptions[0]);

    // The value does not have any special meaning. It is used within a convention where
    // 0 is False and 1 is True (similarly to Yes and No). In the long run, value field
    // should accept other types than string.
    const driverRatingOptions: IChoice[] = [
        { value: '0', label: 'No', enabled: true },
        { value: '1', label: 'Yes', enabled: true },
    ];
    // Custom tailored function to search by value only inside the driverRatingOptions list
    const findOptionFromDriverRatings = (optionValue: string) => findOptionFromList(optionValue, driverRatingOptions);
    const [driverRating, setDriverRating] = useState<IChoice>(findOptionFromDriverRatings(splendInitiatives.driverRating) || driverRatingOptions[0]);

    const applyTripsDiscount = (): boolean => driverRating.value === '1';
    const getTripsDiscount = (): number => applyTripsDiscount() ? Number(findOptionFromTrips(tripCount.value).value) : 0;

    // The line below is a hotfix because for UK journey the context field is populated as Undefined
    // from the customer details hook. The field is used in the payload to the backend and is not allowed to
    // be missing from it (as it would happen for an Undefined value)000000000000000.
    useEffect(() => {updateFormState(UserFormStates.splendInitiatives, {vehicleUtilisation: '0'})}, []);

    useEffect(() => {
        // Update the context each time one of trips, distance or driver rating options change.
        // The discount values are converted from string to number because they come from the
        // IChoice interface, which requires the value field to always be a string. The context
        // discount field shall be a number, hence the data conversion necessity.
        const newDiscount = getTripsDiscount() + Number(distanceAllowance.value) + (additionalDiscount || 0);
        const adjustedWeeklyHireFee = Number((contractDetails.planWeeklyFee - newDiscount).toFixed(2));

        updateFormState(UserFormStates.splendInitiatives, {
            tripCount: tripCount.label,
            tripDiscount: Number(tripCount.value),

            weeklyDistanceAllowance: distanceAllowance.label,
            weeklyDistanceAllowanceDiscount: Number(distanceAllowance.value),

            driverRating: driverRating.value,

            additionalDiscount: additionalDiscount,

            totalDynamicPricing: newDiscount,
            adjustedWeeklyHireFee: adjustedWeeklyHireFee
        });
    }, [tripCount, distanceAllowance, driverRating, additionalDiscount]);

    const itemStyle: React.CSSProperties = {
        flex: 1,
        padding: '10px',
        boxSizing: 'border-box',
    };

    const textStyle: React.CSSProperties = { ...itemStyle, fontWeight: 'bold' };

    const rowStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    };

    const numberStyle: React.CSSProperties = { ...itemStyle, textAlign: 'right' };

    return (
        <div style={{ margin: '2.5vh 0' }}>
            <div>
                <div style={textStyle}>
                    Is driver rating greater or equal than 4.8?
                    <Tooltip message='The discount for the number of trips is counted only if driver rating is at least 4.8' imgIconStyles={{ verticalAlign: 'middle', marginLeft: '5px'}} />
                </div>
                <DropDownMenu
                    menuName='driverRating'
                    defaultVal={driverRating}
                    required={true}
                    choices={driverRatingOptions}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => setDriverRating(findOptionFromDriverRatings(e.target.value))}
                />
                <div style={textStyle}>Trip count</div>
                <DropDownMenu
                    menuName='tripCountDropdownMenu'
                    defaultVal={tripCount}
                    required={true}
                    choices={tripCountOptions}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => setTripCount(findOptionFromTrips(e.target.value))}
                />
            </div>
            <div style={rowStyle}>
                <div style={textStyle}>Discount (£)</div>
                <div style={numberStyle}>{applyTripsDiscount() ? splendInitiatives.tripDiscount : 0}</div>
            </div>
            <HorizontalLineSep />
            <div>
                <div style={textStyle}>Mileage allowance</div>
                <DropDownMenu
                    menuName='mileageAllowanceDropdownMenu'
                    defaultVal={distanceAllowance}
                    required={true}
                    choices={distanceAllowanceOptions}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => setDistanceAllowance(findOptionFromDistanceAllowances(e.target.value))}
                />
            </div>
            <div style={rowStyle}>
                <div style={textStyle}>Discount (£)</div>
                <div style={numberStyle}>{splendInitiatives.weeklyDistanceAllowanceDiscount}</div>
            </div>
            <HorizontalLineSep />
            <div>
                <div style={textStyle}>Additional discount</div>
                <NumericInput
                    name='GenericDiscountInput'
                    style={{ 'minWidth': '50%' }}
                    defaultVal={additionalDiscount}
                    required={false}
                    onChange={(e) => setAdditionalDiscount(Number(e.currentTarget.value))}
                />
            </div>
            <HorizontalLineSep />
            <div style={rowStyle}>
                <div style={textStyle}>Total weekly discount (£)</div>
                <div style={numberStyle}>{splendInitiatives.totalDynamicPricing}</div>
            </div>
            <div style={rowStyle}>
                <div style={textStyle}>Adjusted weekly hire fee (£)</div>
                <div style={numberStyle}>{splendInitiatives.adjustedWeeklyHireFee.toFixed(2)}</div>
            </div>
        </div>
    );
};


const HorizontalLineSep = () => (<div><hr style={{ color: '#E1E1E1', borderColor: '#E1E1E1' }} /></div>);
